import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/lamex_restaurant_story_feature_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const LamexStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How La Mex Restaurant stays organized & keeps their crew updated using Camelo"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How La Mex Restaurant stays organized & keeps their crew
                    updated using Camelo
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.lamexjoliet.com" target="__blank">
                      {`La Mex `}
                    </Link>
                    is a restaurant in Joliet, Illinois, serving authentic and
                    delicious Mexican food that satisfies even the most
                    traditional palates. With its wide range of dishes, drinks,
                    and desserts, the restaurant has thrived.
                  </p>
                  <p className="gr-text-8 text-black">
                    However, as the crew grew,{" "}
                    <Link
                      to="https://www.facebook.com/talia.carlos"
                      target="__blank"
                    >
                      {`Talia`}
                    </Link>
                    , the manager, struggled to keep herself organized. There
                    were times when she granted her workers days off and then
                    completely forgot about them. Then, on the first night she
                    downloaded Camelo, she knew right away it would be worth it
                    in the long run.
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “Nothing more to say than thank you, Camelo team. You
                        guys are phenomenal! You don’t know how grateful I am
                        for finding you guys on the App Store lol!”
                      </p>
                    </blockquote>
                  </p>
                  <h4 className="pt-8 pb-4">
                    A dream comes true and a team that feels like family
                  </h4>
                  <p className="gr-text-8 text-black">
                    Talia’s father had a dream of one day opening his own
                    restaurant from a young age. Through a lot of hard work and
                    risks, he finally made that dream come true. He provided
                    many job opportunities for the employees. Some are still
                    working at La Mex for over a decade, and others reaching two
                    decades.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “Even though it sounds cliché, I see many of the employees
                      more than workers, they are family. We even spend more
                      time at the restaurant than our own homes sometimes.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Supportive clients through challenging times
                  </h4>

                  <p className="gr-text-8 text-black">
                    La Mex’s family recipes have won over lots of guests. Many
                    of them have turned into loyal patrons who supported the
                    restaurant during challenging times.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “I feel like there’s been a balance among all of us. We
                      are able to help each other in need in any situation.
                      Sometimes it’s about hearing each other out and offering
                      support. During those dark Covid times, our clients had
                      been so supportive by reaching out and showing support.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Challenges of managing a growing crew
                  </h4>
                  <p className="gr-text-8 text-black">
                    As the restaurant crew grew, things got busier and started
                    to get messy. It became hard to keep track of what was going
                    on in the restaurant, who was off, and conversations with
                    workers. Embarrassing moments began to arise.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “I struggled to keep myself organized. I’m always on the
                      run. And ever since I started hiring more employees and
                      growing a bigger crew, people would ask for days off, and
                      I’d just say yes in the moment, and totally forget that
                      they’d asked for that certain day off. It was so
                      embarrassing.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">It’s worth it in the long run</h4>
                  <p className="gr-text-8 text-black">
                    In search of an app to help her track things better, Talia
                    got hooked on the Camelo app right from the first night she
                    downloaded it.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “I was so impressed that what I wanted an app to do was
                      actually accomplished. The first night that I downloaded
                      it, I couldn’t sleep that night because I just wanted to
                      get started and complete it. Because I knew right away it
                      was going to be worth it in the long run.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Finally, peace of mind and more time for family
                  </h4>
                  <p className="gr-text-8 text-black">
                    What Talia loves most about Camelo is the convenient
                    <Link
                      to="https://camelohq.com/features/scheduling-software/"
                      target="__blank"
                    >
                      {` scheduling feature`}
                    </Link>
                    .
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “It helped with my anxiety more than anything. No more
                      embarrassing moments when I’ve forgotten someone’s day
                      off. So, more peaceful nights and, especially, more time
                      with my lovely family,” she shared.
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Besides scheduling tools that help management create
                    schedules for workers easily, Camelo also allows management
                    to handle team members’ work hours,{" "}
                    <Link
                      to="https://camelohq.com/features/time-and-attendance/"
                      target="__blank"
                    >
                      {` attendance`}
                    </Link>
                    , messages, and beyond. There’s no need to fumble around
                    trying to keep shifts covered and keep the crew updated.
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “So having this app helps me stay more organized and ease
                      my mind more than anything. Knowing that my crew is
                      organized and can be managed more easily. Also, if there
                      are issues that I have to clear up, the app makes it
                      easier to reach out to everyone at once.”
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    When asked if she’d recommend Camelo to others, Talia was
                    excited,
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Oh hell yes! 🙌🏼 If it changed my life for the better, why
                      wouldn’t I want that for others?”
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default LamexStory;
